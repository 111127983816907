// eslint-disable-next-line no-undef
const apiWlUrl = process.env.API_WHITELABEL_URL;

function handleErrors(response) {
    if (!response.ok) {
        if (response.status !== 404) throw Error(response.statusText);
    }
    return response;
}

export const UrlWLPartner =
    // eslint-disable-next-line no-undef
    process.env.URL_WL_PARTNER || document.location.host;

export const getWLAssets = () =>
    fetch(`${apiWlUrl}/${UrlWLPartner}`)
        .then(handleErrors)
        .then(resp => resp.json());
