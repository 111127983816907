function replaceText(text_json) {
    [...document.querySelectorAll('.highlight-price .price .small')].map(
        el =>
            (el.innerHTML = el.innerHTML.replace(
                'na MaxMilhas',
                `n${text_json.partner_gender} ${text_json.partner_name}`
            ))
    );
    [...document.querySelectorAll('.flight-item-price .exclusive')].map(
        el =>
            (el.innerHTML = el.innerHTML.replace(
                'na MaxMilhas',
                `n${text_json.partner_gender} ${text_json.partner_name}`
            ))
    );
}

function observerAndReactFlightsChanged(text_json) {
    const tabs = document.querySelector('.flights-wrapper');
    const config = { attributes: true, childList: false, subtree: true };
    const observer = new MutationObserver(function() {
        replaceText(text_json);
    });
    observer.observe(tabs, config);
}

function replaceBrand(text_json) {
    const shouldUpdatePatternName =
        text_json.partner_gender && text_json.partner_name;

    if (shouldUpdatePatternName) {
        replaceText(text_json);
    }
}

export const onNextPage = callback => {
    const config = { attributes: false, childList: true, subtree: true };

    const observer = new MutationObserver(function(mutationsList, observeObj) {
        const elFilter = document.querySelector('.filter-flights');

        if (!elFilter) {
            observeObj.disconnect();
            callback();
        }
    });

    const el = document.querySelector('.filter-flights');
    if (el) {
        return observer.observe(document.body, config);
    }
};

export default function(text_json) {
    console.info('Search page loading...');
    let interval;

    interval = setInterval(() => {
        replaceBrand(text_json);
        const elementAirplaneLoader = document.querySelector(
            '.general-info .airports-preloader .lead'
        );
        if (elementAirplaneLoader) {
            const shouldUpdatePatternName =
                text_json.partner_gender && text_json.partner_name;
            if (shouldUpdatePatternName) {
                observerAndReactFlightsChanged(text_json);
            }
            clearInterval(interval);
        }
    }, 800);
}
