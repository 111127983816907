function replaceText(text_json) {
    const shouldUpdatePatternName =
        text_json.partner_gender && text_json.partner_name;

    const shouldRemoveBenefits = text_json.reasons_to_buy === false;
    const shouldModifyBenefits = !!text_json.reasons_to_buy;
    const benefitsEl = document.querySelector('.new-card.benefits-mm-box');

    if (shouldRemoveBenefits) {
        benefitsEl.remove();
    } else if (shouldModifyBenefits) {
        benefitsEl.querySelector('h4').innerText =
            text_json.reasons_to_buy.title;

        if (text_json.reasons_to_buy.description) {
            benefitsEl
                .querySelector('header')
                .appendChild(
                    document.createTextNode(
                        text_json.reasons_to_buy.description
                    )
                );
        }

        const htmlBenefits = text_json.reasons_to_buy.benefits.map(
            (benefit, idx) =>
                `<div class="benefits-mm-box-row${
                    idx + 1 === text_json.reasons_to_buy.benefits.length
                        ? ' border-line'
                        : ''
                }">
            <i class="icon icon-check v-center-columns"></i>
            <p class="value-line v-center-columns"> ${benefit}</p>
        </div>`
        );

        const htmlCertificates = text_json.reasons_to_buy.images.map(
            certificate =>
                `<a href="${certificate.link}" target="_blank">
                    <img class="img-responsive" src="${certificate.image}">
                </a>
                `
        );

        benefitsEl.querySelector('.new-card__content').innerHTML = `
            ${htmlBenefits.join('')}
            <div class="certificates">
                ${htmlCertificates.join('')}
            </div>`;
    } else if (shouldUpdatePatternName) {
        [
            ...document.querySelectorAll(
                '.checkout-wrapper .benefits-mm-box .new-card__title'
            )
        ].map(
            el =>
                (el.innerText = el.innerText.replace(
                    'na MaxMilhas',
                    `n${text_json.partner_gender} ${text_json.partner_name}`
                ))
        );
    }
}

export default function(text_json) {
    console.info('Pre-checkout page loading...');
    replaceText(text_json);
}
