const styles = `
.wl-loader-container{ width: 100vw; height: 100vh; background-color: #fff; position: fixed; z-index: 999999; top: 0; }
.wl-loader { margin: 0; height: 5px; width: 100%; position: absolute; overflow: hidden; background-color: #ddd; }
.wl-loader:before{ display: block; position: absolute; content: ""; left: -200px; width: 200px; height: 5px; background-color: #337ab7; animation: loading 2s linear infinite; }
@keyframes loading { from {left: -200px; width: 30%;} 50% {width: 30%;} 70% {width: 70%;} 80% { left: 50%;} 95% {left: 120%;} to {left: 100%;} }
`;

// Insert visual loader
export function insertLoader() {
    // Create div for spinner
    const body = document.querySelector('body');
    const divSpinner = document.createElement('div');
    divSpinner.setAttribute('class', 'wl-loader-container');
    divSpinner.innerHTML = '<div class="wl-loader"></div>';
    body.prepend(divSpinner);

    // Insert css loader in head
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet) {
        style.styleSheet.cssText = styles;
    } else {
        style.appendChild(document.createTextNode(styles));
    }
}

// Remove visual loader
export function removeLoader() {
    if (document.querySelector('.wl-loader-container'))
        document.querySelector('.wl-loader-container').style.display = 'none';
}
