import { UrlWLPartner } from '../api';

function replaceEnvelope(text_json) {
    // Título H1
    if (text_json.envelope.title_01 !== undefined) {
        const h1 = document.querySelector(
            '#body.comprar-passagens #envelope .envelope-caption h1'
        );
        h1.innerHTML = text_json.envelope.title_01;
    }

    // Título H2
    if (text_json.envelope.title_02 !== undefined) {
        const h2 = document.querySelector(
            '#body.comprar-passagens #envelope .envelope-caption h2'
        );
        h2.innerHTML = text_json.envelope.title_02;
    }

    // Título H3
    if (text_json.envelope.title_03 !== undefined) {
        const envelope_h3 = document.createElement('h3');
        envelope_h3.setAttribute('class', 'hidden-xs hidden-sm');
        envelope_h3.innerHTML = text_json.envelope.title_03;
        document
            .querySelector(
                '#body.comprar-passagens .comprar-passagens--envelope .container-fluid'
            )
            .appendChild(envelope_h3);
        document.querySelector('#envelope .mm-badges').style.display = 'none';
    }

    // Voos baratos e Principais voos
    if (text_json.comprar_passagens === false) {
        document.querySelector(
            '#body.comprar-passagens .comprar-passagens--seosection'
        ).style.display = 'none';
    }

    const shouldUpdatePatternName =
        text_json.partner_gender && text_json.partner_name;
    if (shouldUpdatePatternName) {
        [
            ...document.querySelectorAll(
                '.bestprices-box .travel-leg__chunk--lessprice'
            )
        ].map(el => {
            el.innerHTML = el.innerHTML.replace(
                'na',
                `n${text_json.partner_gender}`
            );
            el.querySelector('.blue').innerText = text_json.partner_name;
        });

        [...document.querySelectorAll('.bestprices-box .is-maxmilhas')].map(
            el => (el.innerText = text_json.partner_name)
        );
    }
}

function replaceHowItWorks(how_it_works) {
    if (how_it_works != false) {
        // Título
        const title_about_box = document.querySelector(
            '#body.comprar-passagens .about .about-box h3'
        );
        title_about_box.innerHTML = how_it_works.title;

        const swiperEl = document.querySelectorAll(
            '#body.comprar-passagens .about .about-box .swiper-slide'
        );

        [...swiperEl].map((el, i) => {
            let dataAtr = el.dataset.swiperSlideIndex
                ? el.dataset.swiperSlideIndex
                : i;

            const iconReplace = el.querySelector(
                '.swiper-slide div[class|=img]'
            );
            iconReplace.style.backgroundImage = `url(${how_it_works.steps[dataAtr].icon})`;

            const titleReplace = el.querySelector('.swiper-slide h4');
            titleReplace.innerHTML = how_it_works.steps[dataAtr].title;

            const textReplace = el.querySelector('.swiper-slide p');
            textReplace.innerHTML = how_it_works.steps[dataAtr].description;
        });
    } else {
        const new_footer = document.querySelector('#body footer.oldfooter');
        new_footer.style.marginTop = '24px';
        document.getElementById('about-us').style.display = 'none';
    }
}

function insertSearchForm(search_miles_only) {
    document.querySelector('#search-form-section').classList.add('hidden');

    const parent = document.querySelector('#envelope > div');
    const sibling = document.querySelector('.mm-badges');
    const newDiv = document.createElement('div');
    newDiv.setAttribute('id', 'search-form-section-custom');
    parent.insertBefore(newDiv, sibling);

    const config = {
        id: 'search-form-section-custom',
        params: search_miles_only ? { _ml: 1 } : {},
        type: 'horizontal',
        logo: false,
        host: 'https://' + UrlWLPartner,
        target: 'same-window'
    };

    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
        config.type = 'vertical';
    }

    var t = 'maxmilhas-widget-js';

    if (!document.getElementById(t)) {
        const a = document.createElement('script');
        a.id = t;
        a.type = 'text/javascript';
        a.src = 'https://widget.maxmilhas.com.br/v2/js/widget.js';
        a.async = !0;
        a.onload = a.onreadystatechange = function() {
            var t = this.readyState;
            if (!t || 'complete' === t || 'loaded' === t)
                try {
                    // eslint-disable-next-line no-undef
                    new MaxMilhas(config);
                } catch (e) {
                    console.error('Ignored WL error', e);
                }
        };
        document.body.appendChild(a);
    }
}

export default function(text_json) {
    console.info('Home page loading...');
    replaceEnvelope(text_json);
    replaceHowItWorks(text_json.how_it_works);
    insertSearchForm(text_json.search_miles_only);
}
