import { UrlWLPartner, getWLAssets } from './api';
import { insertLoader, removeLoader } from './loader';

import homePage from './pages/home';
import preCheckoutPage from './pages/preCheckout';
import searchPage, { onNextPage as onNextPageFromSearch } from './pages/search';
// import checkoutPage, {
//     onNextPage as onNextPageFromCheckout
// } from './pages/checkout';
import afterPaymentPage from './pages/afterPayment';
// import homeHangar from './pages/homeHangar';

const homeRegex = /.+comprar-passagens$/gim;
const searchRegex = /.+busca-passagens-aereas\/(OW|RT)\/.+\/[A-Z]{2}$/gim;
const checkoutRegex = /.+\/pagamento(\?id=.+)?/gim;
const afterPaymentRegex = /.*\/pagamento-finalizado$/gim;

let countCssFiles;
let countJsFiles;
let isWindowLoaded;

let path;
let isComprarPassagem;
let isSearch;
let isPreCheckout;
let isAfterPayment;
let isCheckout;
let isHomeHangar;
let domainsList;
let isRootDomain;
let isCalled = false;

updateVariables();

function updateVariables() {
    isWindowLoaded = false;
    path = document.location.pathname;
    homeRegex.lastIndex = 0;
    searchRegex.lastIndex = 0;
    searchRegex.lastIndex = 0;
    checkoutRegex.lastIndex = 0;
    afterPaymentRegex.lastIndex = 0;
    countCssFiles = 0;
    countJsFiles = 0;

    isComprarPassagem = homeRegex.test(path);
    isPreCheckout = window.location.hash === '#checkout';
    isSearch = searchRegex.test(path) && !isPreCheckout;
    isAfterPayment = afterPaymentRegex.test(path);
    isCheckout = checkoutRegex.test(path) && !isAfterPayment;
    isHomeHangar = path === '/';

    domainsList = [
        /(www|www-stg|mobile|vip|metasearch).maxmilhas.com.br/i,
        /website.mm/i,
        /maxmilhas.local/i,
        /path=\/story/i,
        /website-hangar/i,
        /elb.amazonaws/i,
        /localhost/i
    ];
}

function applyWhiteLabel() {
    if (isCalled) return;
    isCalled = true;
    // if (domainsList.indexOf(UrlWLPartner) !== -1) {
    //     return;
    // }

    if (UrlWLPartner.includes('elb.amazonaws')) {
        return;
    }

    // if (document.location.pathname === '/') {
    //     window.location.replace(`https://${UrlWLPartner}/comprar-passagens`);
    // }

    try {
        isRootDomain = matchInArray(UrlWLPartner, domainsList);
        if (!isRootDomain) insertLoader();
        updateVariables();
        winOnLoad(windowLoaded);
    } catch (error) {
        console.error('whitelabel.js error: ', error);
    }
}

function winOnLoad(callback) {
    if (document.readyState === 'complete') {
        callback();
    } else {
        window.addEventListener('load', callback);
    }
}

async function windowLoaded() {
    try {
        const result = await getWLAssets();

        const partnerQueryString =
            result.whitelabel.text_json.partner_query_string;

        const utmSource = partnerQueryString && partnerQueryString.split('&');

        if (utmSource && isComprarPassagem) {
            if (window.location.href.indexOf(utmSource[0]) === -1) {
                window.location.search = `?${partnerQueryString}`;
            }
        }

        if (result.status && result.status === 404) return;

        countCssFiles = result.whitelabel.css_files.length;
        countJsFiles = result.whitelabel.js_files.length;

        result.whitelabel.css_files.map(addCss);
        result.whitelabel.js_files.map(addJs);

        if (!isRootDomain) {
            replaceLogo(result.whitelabel.text_json.footer.logo);
            removeMenusHeaderHangar();

            const new_footer =
                document.querySelector('#body footer.newfooter') !== null
                    ? document.querySelector('#body footer.newfooter')
                    : document.querySelector('#body footer.oldfooter');

            if (new_footer) {
                footer(
                    new_footer,
                    result.whitelabel.text_json,
                    isComprarPassagem
                );
                footerLinks(result.whitelabel.text_json.footer.links);
                footerSocials(
                    result.whitelabel.text_json.footer.social_networks
                );
            }
        }

        if (isComprarPassagem) {
            // TODO: We can't use this code due php route
            // import(
            //   /* webpackMode: "lazy", webpackPrefetch: true, webpackChunkName: "homeWL" */ "./pages/home"
            // )
            //   .then(home => home(result.whitelabel.text_json))
            //   .catch(error => console.error("Error trying load WL for home", error));
            if (result.whitelabel.text_json.meta)
                changeMetaTags(result.whitelabel.text_json.meta);

            homePage(result.whitelabel.text_json);
        } else if (isSearch) {
            // TODO: We can't use this code due php route
            // import(
            //   /* webpackMode: "lazy", webpackPrefetch: true, webpackChunkName: "searchWL" */ "./pages/search"
            // )
            //   .then(search => {
            //     search(result.whitelabel.text_json);
            //   })
            //   .catch(error =>
            //     console.error("Error trying load WL for search", error)
            //   );
            changeDescriptionToPartnerName(result.whitelabel.text_json);

            searchPage(result.whitelabel.text_json);
            onNextPageFromSearch(applyWhiteLabel);
        } else if (isPreCheckout) {
            // TODO: We can't use this code due php route
            // import(
            //   /* webpackMode: "lazy", webpackPrefetch: true, webpackChunkName: "preCheckoutWL" */ "./pages/preCheckout"
            // )
            //   .then(preCheckout => preCheckout(result.whitelabel.text_json))
            //   .catch(error =>
            //     console.error("Error trying load WL for preCheckout", error)
            //   );
            changeDescriptionToPartnerName(result.whitelabel.text_json);

            preCheckoutPage(result.whitelabel.text_json);
        } else if (isCheckout) {
            // changeDescriptionToPartnerName(result.whitelabel.text_json);
            // changeTitleToPartnerName(result.whitelabel.text_json);
            // checkoutPage(result.whitelabel.text_json);
            // onNextPageFromCheckout(applyWhiteLabel);
        } else if (isAfterPayment) {
            changeDescriptionToPartnerName(result.whitelabel.text_json);
            changeTitleToPartnerName(result.whitelabel.text_json);

            afterPaymentPage(result.whitelabel.text_json);
        } else if (isHomeHangar) {
            // homeHangar(result.whitelabel.text_json);
        } else {
            changeDescriptionToPartnerName(result.whitelabel.text_json);
            changeTitleToPartnerName(result.whitelabel.text_json);

            console.warn('No page match for', path);
        }
    } catch (error) {
        console.error(error); // Just ignore errors
        if (!isRootDomain) finallyRemoveLoader(); // Remove WL on errors
    } finally {
        isWindowLoaded = true;
        if (!isRootDomain) finallyRemoveLoader();
        // removeLoader();
    }
}

function finallyRemoveLoader() {
    if (isWindowLoaded && countCssFiles === 0 && countJsFiles === 0)
        removeLoader();
}

function addCss(css) {
    const fileref = document.createElement('link');
    fileref.setAttribute('rel', 'stylesheet');
    fileref.setAttribute('type', 'text/css');
    fileref.setAttribute('href', css.file_url);
    document.getElementsByTagName('head')[0].appendChild(fileref);

    fileref.onload = () => {
        countCssFiles--;
        finallyRemoveLoader();
    };
}

function addJs(js) {
    const fileref = document.createElement('script');
    fileref.setAttribute('type', 'text/javascript');
    fileref.setAttribute('src', js.file_url);
    document.getElementsByTagName('head')[0].appendChild(fileref);

    fileref.onload = () => {
        countJsFiles--;
        finallyRemoveLoader();
    };
}

function replaceLogo(logo) {
    if (document.querySelector('#body #header-fixed .logo-mm::before')) {
        const selector_logo = '#body #header-fixed .logo-mm::before';
        const logo_principal = document.querySelector(selector_logo);
        logo_principal.style.backgroundImage = logo;
    }
}

function removeMenusHeaderHangar() {
    const headerMenu = document.querySelector('#body .newheader-menu-box');
    if (headerMenu) headerMenu.innerHTML = '';
    const menu = document.querySelector('header .order-md-1');
    if (menu) menu.innerHTML = '';
    const loginBtn = document.querySelector('header .order-md-2');
    if (loginBtn) loginBtn.innerHTML = '';
}

function changeMetaTags(meta) {
    if (meta.description) {
        document.getElementsByTagName('meta')['description'].content =
            meta.description;
    }
    if (meta.title) {
        document.title = meta.title;
    }
}

function changeTitleToPartnerName(text_json) {
    const shouldUpdatePatternName =
        text_json.partner_gender && text_json.partner_name;

    document.title = document.title.replace(
        'na MaxMilhas',
        shouldUpdatePatternName
            ? `n${text_json.partner_gender} ${text_json.partner_name}`
            : ''
    );

    document.title = document.title.replace(
        'MaxMilhas',
        shouldUpdatePatternName ? text_json.partner_name : ''
    );
}

function changeDescriptionToPartnerName(text_json) {
    const shouldUpdatePatternName =
        text_json.partner_gender && text_json.partner_name;

    document.getElementsByTagName('meta')[
        'description'
    ].content = document
        .getElementsByTagName('meta')
        ['description'].content.replace(
            'na MaxMilhas',
            shouldUpdatePatternName
                ? `n${text_json.partner_gender} ${text_json.partner_name}`
                : ''
        );

    document.getElementsByTagName('meta')[
        'description'
    ].content = document
        .getElementsByTagName('meta')
        ['description'].content.replace(
            'MaxMilhas',
            shouldUpdatePatternName ? text_json.partner_name : ''
        );
}

function footerLinks(links) {
    if (!links) {
        return;
    }

    const linksHtml = links.reduce((acc, next) => {
        acc += `<a href="${next.url}" target="_blank">${next.title}</a>`;
        return acc;
    }, '');

    const urls = document.querySelector(
        '#body footer .ml-footer .infos .ml-nav'
    );
    urls.innerHTML = linksHtml;
}

function footer(element, retorno, isHome) {
    const defaultCopyright = `Copyright &copy; ${new Date().getFullYear()}`;
    const logoMM = `<div class="ml-maxmilhas-partner maxmilhas-partner">
  <p>Parceiro</p>
  <a href="https://www.maxmilhas.com.br/">
  <img src="https://mm-prd-marketing-whitelabel.s3.amazonaws.com/image/logo-white.svg"/>
  </a>
</div>
`;
    const copyright = retorno.footer.copyright
        ? retorno.footer.copyright
        : defaultCopyright;
    const footerTemplate = `<footer class="ml-footer">
  <img src="${retorno.footer.logo}" class="ml-logo-footer">
  <div class="container-fluid">
      <div class="row infos">
          <nav class="ml-nav"> </nav>
          <div class="ml-connect connect">
              <div class="ml-social-media social-media">
                  <a id="ft-facebook" target="_blank" href="https://www.maxmilhas.com.br/comprar-passagens" style="display: none;"><i class="icon-facebook"></i></a>
                  <a id="ft-instagram" target="_blank" href="https://www.maxmilhas.com.br/comprar-passagens" style="display: none;"><i class="icon-instagram"></i></a>
                  <a id="ft-twitter" target="_blank" href="https://www.maxmilhas.com.br/comprar-passagens" style="display: none;"><i class="icon-twitter"></i></a>
                  <a id="ft-youtube" target="_blank" href="https://www.maxmilhas.com.br/comprar-passagens" style="display: none;"><i class="icon-youtube"></i></a>
                  <a id="ft-linkedin" target="_blank" href="https://www.maxmilhas.com.br/comprar-passagens" style="display: none;"><i class="icon-linkedin"></i></a>
              </div>
          </div>
          ${retorno.footer.mm_logo ? logoMM : ''}
          <div class="copyright-wrapper">
            <p>${copyright}</p>
          </div>
      </div>
  </div>
</footer>`;

    const aboutPartner = retorno.about
        ? `<section class="ml-about">
            <div class="container-fluid ml-about-container">
                <div class="row common-box ml-about-content">
                    <img src="${retorno.about.logo}" class="ml-logo-about-footer">
                    <div class="content">
                        <h4>${retorno.about.title}</h4>
                        <p>${retorno.about.description}</p>
                    </div>
                </div>
            </div>
        </section>`
        : '';

    element.innerHTML = `${isHome ? aboutPartner : ''} ${footerTemplate}`;

    if (!isHome) {
        element.style.marginTop = '24px';
    }

    // MM Logo
    // if (retorno.footer.mm_logo == false) {
    //     const social_border = document.querySelector(
    //         '#body footer .ml-footer .social-media'
    //     );
    //     mm_logo.style.border = '0px';
    // }
}

// Adicionar redes sociais no footer
function footerSocials(social) {
    if (!social) {
        document.querySelector('#body footer .infos .ml-nav').style.display =
            'none';
        document.querySelector('#body footer .infos .connect').style.display =
            'none';

        return;
    }

    if (social.facebook !== undefined) {
        document.getElementById('ft-facebook').href = social.facebook;
        document.getElementById('ft-facebook').style.display = '';
    }

    if (social.instagram !== undefined) {
        document.getElementById('ft-instagram').href = social.instagram;
        document.getElementById('ft-instagram').style.display = '';
    }

    if (social.twitter !== undefined) {
        document.getElementById('ft-twitter').href = social.twitter;
        document.getElementById('ft-twitter').style.display = '';
    }

    if (social.youtube !== undefined) {
        document.getElementById('ft-youtube').href = social.youtube;
        document.getElementById('ft-youtube').style.display = '';
    }

    if (social.linkedin !== undefined) {
        document.getElementById('ft-linkedin').href = social.linkedin;
        document.getElementById('ft-linkedin').style.display = '';
    }
}

function matchInArray(string, expressions) {
    var len = expressions.length,
        i = 0;

    for (; i < len; i++) {
        if (string.match(expressions[i])) {
            return true;
        }
    }

    return false;
}

export default function() {
    console.info(
        `whitelabel applyed for`,
        // eslint-disable-next-line no-undef
        process.env.URL_WL_PARTNER
    );

    applyWhiteLabel();
}

applyWhiteLabel();
