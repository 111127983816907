function replaceText(text_json) {
    const shouldUpdatePatternName =
        text_json.partner_gender && text_json.partner_name;

    if (shouldUpdatePatternName) {
        const element1 = document.querySelector(
            '.afterpaymentpage section.card-details ul > li:nth-child(5) strong'
        );
        element1.innerHTML = element1.innerHTML.replace(
            'MaxMilhas',
            text_json.partner_name
        );
        element1.innerHTML = element1.innerHTML.replace(
            'pela',
            `pel${text_json.partner_gender}`
        );

        const elementP = document.querySelector(
            '#quest-form-wrapper .form-quest div:nth-child(2) > div > p'
        );
        elementP.innerHTML = elementP.innerHTML.replace(
            'a MaxMilhas',
            `${text_json.partner_gender} ${text_json.partner_name}`
        );
    }
}

export default function(text_json) {
    console.info('After payment page loading...');
    replaceText(text_json);
}
